import firebase from "gatsby-plugin-firebase";
import axios from "axios";
export const isBrowser = () => typeof window !== "undefined"

export const getGatsbyUser = () => {
    if (isBrowser()){
        if(!!window.localStorage.getItem("gatsbyUser")){
            return JSON.parse(window.localStorage.getItem("gatsbyUser"))
        }
    }
    return {}
}

export const getToken = () => {
    if (isBrowser()){
        if(!!window.localStorage.getItem("token")){
            return JSON.parse(window.localStorage.getItem("token"))
        }
    }
    return {}
}
export function setGatsbyUser (user) {
    if (typeof user === "object" && user !== null){
            user.getIdToken().then(function (tokenId){
                setToken(tokenId)
            })
            window.localStorage.setItem("gatsbyUser", JSON.stringify(user))
    }
}

export function setToken(token){
    window.localStorage.setItem("token", JSON.stringify(token))
    axios.defaults.headers.common['Authorization'] = token;
}
export const isLoggedIn = () => {
    try{
        const user = getGatsbyUser()
        return !!user.displayName && user.email.split('@')[1]=="acguanacaste.ac.cr"
    }catch (e){
        return false
    }
}

function removeToken() {
    window.localStorage.removeItem("token")
}
function  removeUser(){
    window.localStorage.removeItem("gatsbyUser")
}

export const logoutGatsbyUser = () => {
    axios.defaults.headers.common['Authorization'] = null;
    removeUser()
    removeToken()
}

export const getFBToken = (force = false) =>{
    if (firebase.auth().currentUser != null) {
         firebase.auth().currentUser.getIdToken(force).then(function (tokenId) {
            setToken(tokenId)
            return tokenId
        });
        return getToken();
    }
}

/*
Checks if the current token is valid for at least the next 30 seconds if not force renew
 */
export function checkTokenExpiration(){
    const token = getToken();

    if (token){
        const currentTs =  Math.floor(Date.now() / 1000)
        const expirationTS = JSON.parse(atob(token.split('.')[1])).exp
        const tokenLife = expirationTS - currentTs
        if (tokenLife < 30){ //si le quedan menos de 30 segundos forzar el renew
            getFBToken(true)
        }/*else {
            console.log("auth Token ok", tokenLife /60 )
        }*/
    }
}
