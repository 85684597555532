import React, {createContext, useContext} from "react";
import FilterStore from "../stores/filter";
import {useLocalObservable} from "mobx-react-lite";

const FilterContext = createContext(null)
//Provider para almacenar filter en Context, de forma que esté global cuando uno cambia de páginas
export const FilterProvider = ({children}) =>{
    const filter = useLocalObservable(() => FilterStore())
    
    return (
        <FilterContext.Provider value={filter}>
            {children}
        </FilterContext.Provider>
    )
}
//Hook para usar el fiter desde Context
export const useFilterStore = () => useContext(FilterContext)
