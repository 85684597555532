import React, {createContext} from "react";
import firebase from "gatsby-plugin-firebase";
import useAuthState from "../services/useAuthState";


export const AuthContext = createContext({})

const AuthProvider = ({ children}) => {
    const [user, loading] = useAuthState(firebase)

    return (
        <AuthContext.Provider value={{user, loading }}>
            {children}
        </AuthContext.Provider>
    )
}
export default AuthProvider
