import moment from "moment";

//Este es el objeto que almacena los datos del filtro

export default function FilterStore (){
    let minDate = moment()
    minDate = moment(minDate).format('yyyy-MM-DD');
    let max =   moment().add(1, 'day');
    max =  moment(minDate).format('yyyy-MM-DD');
    
    return {
        desde : minDate,
        hasta : max,
        sector : "Marino",
        tipo : "turistas",
        setDesde(fecha){
            this.desde = fecha
        },
        setHasta(fecha){
            this.hasta = fecha
        },
        setSector(sectorACG){
            this.sector = sectorACG
        },
        setTipo(tipo){
          this.tipo = tipo
        },
        clear(){
            this.desde = minDate
            this.hasta = max
            this.sector = "Marino"
        },
        get displayDesde() {
            return moment(this.desde).locale("es").format('D MMMM YYYY' )
        },
        get displayHasta(){
          return moment(this.hasta).locale("es").format('D MMMM YYYY' )
        }
        
    }
}
