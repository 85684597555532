import "firebase/auth"
import React from "react";
import AuthProvider from "./src/context/auth";
import {FilterProvider} from "./src/context/filter";


export const wrapRootElement = ({element}) => {
    return (
        <AuthProvider>
            <FilterProvider>
                {element}
            </FilterProvider>
        </AuthProvider>
    )
}
